import styled from "styled-components";
import { MdOutlineDarkMode, MdOutlineLightMode, MdOutlineMenu, MdClose, MdOpenInNew } from "react-icons/md";
import NavLink from "./NavLink";
import { useEffect, useState } from "react";
import mobileStyles from "../mobile.module.css";

const Nav = styled.nav`
	width: 100%;
	height: 60px;
	padding: 10px 40px;
	position: fixed;
	top: 0px;
	left: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--background);
	z-index: 1;
`;

const MainHeader = styled.a`
	margin: auto;
	color: var(--header-secondary);
	text-decoration: none;
	font-size: 24px;

	strong {
		color: var(--header);
	}
`;

const Links = styled.ul`
	display: flex;
	flex-direction: column;
	padding: 25px 40px;
	gap: 45px;
	list-style: none;
`;

const Menu = styled.div`
	top: 60px;
	left: 0px;
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: var(--background);
	z-index: 1;
	opacity: 0.95;
	transition: opacity 0.2s ease-in, visibility 0.2s, background-color 0.1s ease-in, color 0.1s ease-in;

	&.hidden {
		visibility: hidden;
		opacity: 0;
	}
`;

const NavButton = styled.button`
	height: 30px;
	width: 30px;
	padding: 4px;
	border: none;
	background-color: transparent;
	cursor: pointer;
	color: var(--header-secondary);
	font-size: 22px;
	border-radius: 5px;
	@media (hover: hover) {
		&:hover {
			background-color: var(--background-secondary);
			color: var(--header);
		}

		&:active {
			background-color: var(--background-tertiary);
			color: var(--header);
		}
	}
`;

const NavSubLinks = styled.ul`
	display: flex;
	flex-direction: column;
	padding: 25px 40px 0 40px;
	gap: 25px;
	list-style: none;

	& .navLink {
		margin-right: auto;
	}

	& li::before {
		content: "-";
		color: var(--header-tertiary);
		display: inline-block;
		width: 1em;
		margin-left: -1em;
	}
`;

export default function MobileNavbar(props) {
	const [menuOpen, setMenuOpen] = useState(false);

	const closeMenu = () => setMenuOpen(false);

	useEffect(() => {
		document.body.style.overflow = menuOpen ? "hidden" : "hidden scroll";
		document.querySelector("main").style.pointerEvents = menuOpen ? "none" : "all";
	}, [menuOpen]);

	return (
		<>
			<Nav className={mobileStyles.nav}>
				<NavButton onClick={() => setMenuOpen(!menuOpen)}>
					{menuOpen ? <MdClose /> : <MdOutlineMenu />}
				</NavButton>
				<MainHeader href="/">
					{"<"} <strong>Neodymium</strong> {"/>"}
				</MainHeader>
				<NavButton onClick={props.toggleDarkMode}>
					{props.darkMode ? <MdOutlineLightMode /> : <MdOutlineDarkMode />}
				</NavButton>
			</Nav>
			<Menu className={!menuOpen && "hidden"}>
				<Links className={mobileStyles.navLinks}>
					<li>
						<NavLink onClick={closeMenu} to="/#about">
							About
						</NavLink>
					</li>
					<li>
						<NavLink onClick={closeMenu} to="/#skills">
							Skills
						</NavLink>
					</li>
					<li>
						<NavLink onClick={closeMenu} to="/#projects">
							Projects
						</NavLink>
						<NavSubLinks>
							<li>
								<NavLink onClick={closeMenu} href="https://github.com/Neodymium7/BetterDiscordStuff">
									BetterDiscord Addons <MdOpenInNew fontSize="1.125em" />
								</NavLink>
							</li>
							<li>
								<NavLink onClick={closeMenu} href="https://github.com/Neodymium7/BundleBD">
									BundleBD <MdOpenInNew fontSize="1.125em" />
								</NavLink>
							</li>
						</NavSubLinks>
					</li>
				</Links>
			</Menu>
		</>
	);
}

import styled from "styled-components";
import mobileStyles from "../mobile.module.css";

const Heading = styled.h1`
	color: var(--header);
	font-size: 28px;

	@media screen and (max-width: 887px) {
		font-size: 18px;
	}
`;

const Description = styled.p`
	font-size: 18px;
	color: var(--header-secondary);
	margin-bottom: auto;
`;

const Buttons = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: end;
	gap: 8px;
	margin-top: auto;
`;

const ProjectContainer = styled.div`
	background-color: var(--background-secondary);
	padding: 20px;
	border-radius: 8px;
	width: 400px;
	height: 200px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 8px;
	transform: scale(0.9);
	opacity: 0.8;
	transition: transform 0.1s ease-in, opacity 0.1s ease-in, background-color 0.1s ease-in, color 0.1s ease-in;

	&:hover {
		transform: scale(1);
		opacity: 1;
	}

	@media screen and (max-width: 887px) {
		width: 275px;
		height: 175px;
	}
`;

export default function Project(props) {
	return (
		<ProjectContainer className={mobileStyles.project}>
			<Heading className={mobileStyles.projectHeading}>{props.title}</Heading>
			<Description className={mobileStyles.projectDescription}>{props.description}</Description>
			<Buttons>{props.children}</Buttons>
		</ProjectContainer>
	);
}

import { MainSection } from "../components/sections";
import { Heading, Subheading } from "../components/headings";
import styled from "styled-components";

const Main = styled(MainSection)`
	min-height: 100vh;
`;

export default function Error() {
	return (
		<Main>
			<Heading>Error 404</Heading>
			<Subheading>
				This page may or may not exist.... but chances are, it doesn't. <br /> Make sure the link is typed correctly!
			</Subheading>
		</Main>
	);
}

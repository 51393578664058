import styled from "styled-components";
import mobileStyles from "../mobile.module.css";

const StyledHeading = styled.h1`
	color: var(--header);
	font-size: 72px;
	text-align: center;
`;

const StyledSubheading = styled.h4`
	color: var(--header-secondary);
	font-weight: 400;
	font-size: 22px;
	text-align: center;
	max-width: ${props => props.maxWidth};
`;

export function Heading(props) {
	return <StyledHeading className={mobileStyles.heading} {...props} />;
}

export function Subheading(props) {
	return <StyledSubheading className={mobileStyles.subheading} {...props} />;
}

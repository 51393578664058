import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import MobileNavbar from "./components/MobileNavbar";
import Home from "./routes/home";
import Error from "./routes/error";
import { useEffect, useState } from "react";

const reload = () => window.location.reload();

export default function App() {
	const [darkMode, setDarkMode] = useState(
		localStorage.getItem("darkMode") ? localStorage.getItem("darkMode") === "true" : true
	);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		localStorage.setItem("darkMode", darkMode);
		document.body.style.backgroundColor = darkMode ? "hsl(0, 0%, 7%)" : "hsl(0, 0%, 93%)";
	}, [darkMode]);

	useEffect(() => {
		const setCurrentWindowWidth = () => setWindowWidth(window.innerWidth);

		window.addEventListener("resize", setCurrentWindowWidth);
		return () => window.removeEventListener("resize", setCurrentWindowWidth);
	}, []);

	return (
		<BrowserRouter>
			<div className={darkMode ? "app dark" : "app light"}>
				{windowWidth > 800 ? (
					<Navbar toggleDarkMode={() => setDarkMode(!darkMode)} darkMode={darkMode} />
				) : (
					<MobileNavbar toggleDarkMode={() => setDarkMode(!darkMode)} darkMode={darkMode} />
				)}
				<Routes>
					<Route path="/" element={<Home darkMode={darkMode} />} />
					<Route path="/BetterDiscordStuff/CSS/AddServerDarkMode.css" onEnter={reload} />
					<Route path="/game" onEnter={reload} />
					<Route path="*" element={<Error />} />
				</Routes>
			</div>
		</BrowserRouter>
	);
}

import { Link } from "react-router-dom";
import styled from "styled-components";
import mobileStyles from "../mobile.module.css";

const StyledButton = styled.button`
	text-decoration: none;
	color: var(--header-tertiary);
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 8px;
	border-radius: 5px;
	background-color: var(--background-secondary);
	font-size: 18px;
	border: none;
	cursor: pointer;

	@media (hover: hover) {
		&:hover {
			color: var(--header-secondary);
			background-color: var(--background-tertiary);
		}

		&:active {
			color: var(--header-secondary);
			background-color: var(--button-active);
		}
	}
`;

export default function Button(props) {
	return props.href || props.to ? (
		<StyledButton
			className={mobileStyles.button}
			as={props.to ? Link : "a"}
			{...props}
			target={props.href && "_blank"}
			rel={props.href && "noopener noreferrer"}
		>
			{props.children}
		</StyledButton>
	) : (
		<StyledButton className={mobileStyles.button} {...props}>
			{props.children}
		</StyledButton>
	);
}

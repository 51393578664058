import styled from "styled-components";
import mobileStyles from "../mobile.module.css";

const StyledSection = styled.section`
	height: 100vh;
	width: 100%;
	scroll-snap-align: start;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 100px 40px 40px 40px;
	gap: 20px;
`;

const Main = styled.main`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 100px 40px 40px 40px;
	gap: 20px;
`;

export function ScrollSection(props) {
	return (
		<StyledSection className={props.className || mobileStyles.section} id={props.id}>
			{props.children}
		</StyledSection>
	);
}

export function MainSection(props) {
	return <Main {...props}>{props.children}</Main>;
}

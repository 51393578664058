import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
	text-decoration: none;
	color: var(--header-secondary);
	font-weight: 600;
	padding: 4px 8px;
	border-radius: 5px;
	white-space: nowrap;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;

	@media (hover: hover) {
		&:hover {
			background-color: var(--background-secondary);
			color: var(--header);
		}

		&:active {
			background-color: var(--background-tertiary);
			color: var(--header);
		}
	}
`;

export default function NavLink(props) {
	return props.to?.includes("#") ? (
		<StyledLink onClick={props.onClick} className="navLink" as={HashLink} smooth to={props.to}>
			{props.children}
		</StyledLink>
	) : (
		<StyledLink
			onClick={props.onClick}
			as={props.href && "a"}
			href={props.href}
			target={props.href && "_blank"}
			rel={props.href && "noopener noreferrer"}
			className="navLink"
			to={props.to}
		>
			{props.children}
		</StyledLink>
	);
}

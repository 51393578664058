import styled from "styled-components";
import { MdOpenInNew, MdOutlineDarkMode, MdOutlineLightMode } from "react-icons/md";
import NavLink from "./NavLink";
import NavDropdown from "./NavDropdown";

const Nav = styled.nav`
	width: 100%;
	height: 60px;
	padding: 10px 40px;
	position: fixed;
	top: 0px;
	left: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--background);
	z-index: 1;
`;

const MainHeader = styled.a`
	margin-right: auto;
	color: var(--header-secondary);
	text-decoration: none;
	font-size: 24px;

	strong {
		color: var(--header);
	}
`;

const Links = styled.ul`
	display: flex;
	padding: 0 8vw;
	list-style: none;
	gap: 8vw;
`;

const NavButton = styled.button`
	height: 30px;
	width: 30px;
	padding: 4px;
	border: none;
	background-color: transparent;
	cursor: pointer;
	color: var(--header-secondary);
	font-size: 22px;
	border-radius: 5px;

	&:hover {
		background-color: var(--background-secondary);
		color: var(--header);
	}

	&:active {
		background-color: var(--background-tertiary);
		color: var(--header);
	}
`;

export default function Navbar(props) {
	return (
		<Nav>
			<MainHeader href="/">
				{"<"} <strong>Neodymium</strong> {"/>"}
			</MainHeader>
			<Links>
				<li>
					<NavLink to="/#about">About</NavLink>
				</li>
				<li>
					<NavLink to="/#skills">Skills</NavLink>
				</li>
				<li>
					<NavDropdown title="Projects" to="/#projects">
						<NavLink href="https://github.com/Neodymium7/BetterDiscordStuff">
							BetterDiscord Addons <MdOpenInNew fontSize="1.125em" />
						</NavLink>
						<NavLink href="https://github.com/Neodymium7/BundleBD">
							BundleBD <MdOpenInNew fontSize="1.125em" />
						</NavLink>
					</NavDropdown>
				</li>
			</Links>
			<NavButton onClick={props.toggleDarkMode}>
				{props.darkMode ? <MdOutlineLightMode /> : <MdOutlineDarkMode />}
			</NavButton>
		</Nav>
	);
}

import styled from "styled-components";
import mobileStyles from "../mobile.module.css";

const width1 = 1060;
const width2 = 700;
const width3 = 560;
const height1 = 980;
const height2 = 900;
const height3 = 800;

const Heading = styled.h1`
	color: var(--header);
	font-weight: 700;
	font-size: 28px;
	margin-top: auto;

	@media screen and (max-width: ${width1}px) {
		font-size: 24px !important;
	}
	@media screen and (max-height: ${height1}px) {
		font-size: 24px !important;
	}
	@media screen and (max-width: ${width2}px) {
		margin: auto;
		font-size: 18px !important;
	}
	@media screen and (max-height: ${height2}px) {
		margin: auto;
		font-size: 18px !important;
	}
	@media screen and (max-width: ${width3}px) {
		font-size: 15px !important;
	}
	@media screen and (max-height: ${height3}px) {
		font-size: 15px !important;
	}
`;

const Description = styled.div`
	color: var(--header-secondary);
	font-size: 18px;
	margin-bottom: auto;
	margin-top: 12px;
	text-align: center;

	@media screen and (max-width: ${width1}px) {
		font-size: 16px !important;
	}
	@media screen and (max-height: ${height1}px) {
		font-size: 16px !important;
	}
	@media screen and (max-width: ${width2}px) {
		display: none;
	}
	@media screen and (max-height: ${height2}px) {
		display: none;
	}
`;

const Info = styled.div`
	opacity: 0;
	transition: opacity 0.1s ease-in;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 16px;
	z-index: 1;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
`;

const SkillContainer = styled.a`
	background-color: var(--background-secondary);
	padding: 24px;
	border-radius: 8px;
	width: 200px;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: transform 0.1s ease-in, background-color 0.1s ease-in, color 0.1s ease-in;
	font-size: 160px;
	position: relative;

	svg {
		transition: filter 0.1s ease-in, background-color 0.1s ease-in, color 0.1s ease-in;
		z-index: 0;
	}
	@media (hover: hover) {
		&:hover {
			transform: scale(1.1);

			svg {
				filter: blur(8px) opacity(0.2);
			}

			${Info} {
				opacity: 1;
			}
		}
	}

	@media screen and (max-width: ${width1}px) {
		width: 170px;
		height: 170px;
		font-size: 130px;
	}
	@media screen and (max-height: ${height1}px) {
		width: 170px;
		height: 170px;
		font-size: 130px;
	}
	@media screen and (max-width: ${width2}px) {
		width: 130px;
		height: 130px;
		font-size: 90px;
	}
	@media screen and (max-height: ${height2}px) {
		width: 130px;
		height: 130px;
		font-size: 90px;
	}
	@media screen and (max-width: ${width3}px) {
		width: 110px;
		height: 110px;
		font-size: 70px;
	}
	@media screen and (max-height: ${height3}px) {
		width: 110px;
		height: 110px;
		font-size: 70px;
	}
`;
export default function Skill(props) {
	return (
		<SkillContainer className={mobileStyles.skill} href={props.link} target="_blank" rel="noopener noreferrer">
			{props.children}
			<Info>
				<Heading>{props.title}</Heading>
				<Description>{props.description}</Description>
			</Info>
		</SkillContainer>
	);
}

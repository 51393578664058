import styled from "styled-components";
import NavLink from "./NavLink";

const DropdownContainer = styled.div`
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.2s ease-in, visibility 0.2s;
	padding-top: 8px;
	position: fixed;
`;

const Container = styled.div`
	&:hover > .navLink {
		background-color: var(--background-secondary);
		color: var(--header);
	}

	&:hover > .navLink:active {
		background-color: var(--background-tertiary);
		color: var(--header);
	}

	&:hover ${DropdownContainer} {
		visibility: visible;
		opacity: 1;
		transition: opacity 0.2s 0.2s ease-in, visibility 0.2s 0.2s;
	}
`;

const Dropdown = styled.div`
	background-color: var(--background-secondary);
	border-radius: 5px;
	padding: 8px;
	display: flex;
	width: fit-content;
	flex-direction: column;

	& .navLink:hover {
		background-color: var(--background-tertiary);
		color: var(--header);
	}

	& .navLink:active {
		background-color: var(--button-active);
		color: var(--header);
	}
`;

export default function NavDropdown(props) {
	return (
		<Container>
			<NavLink onClick={props.onClick} to={props.to}>
				{props.title}
			</NavLink>
			<DropdownContainer>
				<Dropdown>{props.children}</Dropdown>
			</DropdownContainer>
		</Container>
	);
}
